<template>
  <div class="add-wrap">
    <div v-wechat-title="$route.meta.title"></div>
    <!-- 导航页 -->
    <navmenu class="navmenu"></navmenu>
    <!-- 空白隔开 -->
    <!-- <div style="height:90px;width:100%"></div> -->
    <div class="add-contant-wrap">
      <div class="toolbar-wrap">
        <span class="add-box">
          <div class="add-icon">+</div>
          <div class="addtype-box">
            <ul>
              <li @click="addContant('textarea')">文本框</li>
              <li @click="addContant('title1')">一级标题</li>
              <li @click="addContant('title2')">二级标题</li>
              <li @click="addContant('ee')">代码块</li>
              <li @click="addContant('img')">图片</li>
            </ul>
          </div>
        </span>
        <span class="save-box" @click="sendart">
          保存
        </span>
      </div>
      <div class="contant-box">
        <div class="contant" @click="activeItem(index)" v-for="(item,index) in artList" :key="item.id" :class="{'active':item.active == true}">
          <div class="deleteBut" @click="deleteItem(index)">X</div>
          <div v-if="item.type == 'textarea'">
            <el-input type="textarea" v-model="item.contant"></el-input>
          </div>
          <div v-if="item.type == 'title1'" class="title1">
            <el-input type="text" v-model="item.contant"></el-input>
          </div>
          <div v-if="item.type == 'title2'" class="title2">
            <el-input type="text" v-model="item.contant"></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navmenu from './components/navmenu.vue'
export default {
  name: 'addArticle',
  components: {
    navmenu
  },
  data () {
    return {
      artTitle: '',
      myarticle: '',
      titleimg: '',
      mydate: Date(),
      fileList: [
        // { url: '' }
      ],
      artList: [],
      index: 0
    }
  },
  methods: {
    addContant (type) {
      this.index++
      this.artList.push({
        type: type,
        index: this.index,
        contant: '',
        imgSize: {
          width: '',
          height: ''
        }, // 图片大小
        strong: false, // 加粗
        italic: false, // 斜体
        fontSize: type === 'title1' ? '24px' : type === 'title2' ? '20px' : type === 'textarea' ? '16px' : '', // 文字大小
        align: 'left', // 布局
        color: 'black', // 文字颜色
        active: false
      })
    },
    deleteItem (index) {
      this.artList.splice(index, 1)
    },
    activeItem (i) {
      this.artList.forEach((val, index) => {
        if (i === index) {
          val.active = true
        } else {
          val.active = false
        }
      })
      console.log(this.artList)
    },
    sendart () {
      if (this.artList.length === 0) {
        alert('请输入内容')
      } else {
        this.myarticle = JSON.stringify(this.artList)
        this.myarticle = this.myarticle.replace(/\n/g, 'HHbr')
        this.myarticle = this.myarticle.replace(/ /g, 'shift7nbsp;')
        this.myarticle = this.myarticle.replace(/&/g, 'shift7')
        // this.myarticle = this.myarticle.replace(/(\n\r|\r\n|\r|\n)/g, '<br/>')
        this.myarticle = this.myarticle.replace('#', '井')
        this.$http.post(`/addarea?name=${this.Username}&title=${this.artTitle}&titleimg=${this.titleimg}&time=${this.mydate}&textarea=${this.myarticle}`).then(function (res) {
          console.log(res.data)
        })
        this.artTitle = ''
        this.myarticle = ''
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="stylus" scoped>
.navmenu
  z-index 999
  opacity 0.9
  width 100%
  position fixed
  top 0
  left 0
.add-wrap
  width 100%
  // min-height 100vh
  overflow: hidden
  // background yellow
  // margin-top: 90px
  .add-contant-wrap
    width: 100%
    height: calc(100% - 90px)
    position: fixed
    top: 90px
    left: 0
    overflow-y: scroll
    // margin-top: 90px
  .toolbar-wrap
    width: 100%
    background: white
    height: 30px
    padding: 0 5px
    z-index: 999
    position: fixed
    top: 90px
    left: 0
    box-sizing: border-box
    span
      display: inline-block
      line-height: 30px
    .addtype-box
      display: none
      position: relative
      z-index: 999
      width: 150px
      height: auto
      padding: 10px 0
      background: white
      border: 1px solid yellow
    .add-box
      .add-icon
        width: 30px
        height: 30px
        border: 1px dashed #333
        text-align: center
        line-height: 30px
    .add-box:hover .addtype-box
      display: block
      position: relative
      z-index: 999
      li
        font-size: 14px
        padding: 3px 15px
      li:hover
          background: yellow
    .save-box
      float right
      font-size: 16px
      height: 30px
      padding: 0 5px
    .save-box:hover
      background: #08a3d9
      cursor pointer
      color  #fff
  .contant-box
    height calc(100vh - 130px)
    width: 100%
    padding: 0 20px
    margin-top: 40px
    box-sizing: border-box
    // background: #dad5c7
    .contant:before
      content: 'O'
      color: black
      position: absolute
      left: -15px
      height: 100%
      z-index: 101
    .active:before
      color: yellow
    .contant
      margin-top: 20px
      position: relative
      .deleteBut
        position: absolute
        right: -5px
        top: -5px
        z-index: 100
        cursor pointer
      .title1 /deep/.el-input__inner
        font-size: 24px
      /deep/.el-textarea__inner
        border: none
      /deep/.el-input__inner
        border: none
        background-color: #fff !important
      /deep/.el-input__inner:hover
        border: none
      .title2 /deep/.el-input__inner
        font-size: 20px
</style>
